import React from "react";
import styled from "styled-components";
import breakpoints from "../assets/styles/breakpoints";
import CrossIcon from "../assets/icons/close.svg";

const Shadow = styled.div`
  @media(min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  overflow-x: scroll;
  position: relative;
  
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const ClearFilters = styled.h4`
  display: none;
  
  @media(min-width: ${breakpoints.md}) {
    display: block;
    font-size: 1rem;
    line-height: 1.188rem;
    color: var(--primary);
    margin: 0;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
      color: var(--lightenPrimary);
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${props => props.flexwrap ? "wrap" : "no-wrap" };
  grid-row-gap: 0.625rem;
  grid-column-gap: 0.625rem;
  padding-left: 1rem;
  
  @media(min-width: ${breakpoints.md}) {
    margin-top: 0.625rem;
    padding-left: 0;
    flex-wrap: wrap;
    position: relative;
    left: unset;
    top: unset;
  }
`;

const FilterButton = styled.button`
  background: var(--moreLighterPrimary);
  font-size: 12px;
  font-weight: 400;
  color: var(--sharkGrey);
  padding: 0.438rem 0.625rem 0.438rem 1.813rem;
  border-radius: 15px;
  width: max-content;
  position: relative;
  height: 1.875rem;
  white-space: nowrap;
  border: none;
  cursor: pointer;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const Cross = styled.div`
  background: #F5F5F5;
  height: 0.875rem;
  width: 0.875rem;
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0.625rem;
  
  img {
    height: 0.375rem;
    width: 0.375rem;
  }
`;

const AppliedFilters = ({allFilters, toggleFilter, handleClearFilters, noResults, toggleMemberDiscount}) => {

    return (
        <React.Fragment>
            {!noResults && <Shadow />}
            <ButtonsContainer>
                {!noResults && <ClearFilters onClick={handleClearFilters}>Clear filters</ClearFilters>}
                <Buttons flexwrap={noResults}>
                    {allFilters['stars'].map((star, index) => {
                        return (
                            <FilterButton key={index} onClick={() => toggleFilter('stars', star)}>
                                <Cross>
                                    <img src={CrossIcon} alt="Remove filter"/>
                                </Cross>                            
                                {star.value}
                            </FilterButton>
                        )
                    })}

                    {allFilters['property'].map((property, index) => {
                        return (
                            <FilterButton key={index} onClick={() => toggleFilter('property', property)}>
                                <Cross>
                                    <img src={CrossIcon} alt="Remove filter"/>
                                </Cross>
                                {property.label}
                            </FilterButton>
                        )
                    })}

                    {Object.entries(allFilters.appliedFilters).map(function(filter, index) {
                        
                        if (filter[0] === 'sort'){
                            return;
                        }

                        if (filter[0] === 'memberRate' && filter[1]){
                            return (
                                <FilterButton key={index} onClick={toggleMemberDiscount}>
                                    <Cross>
                                        <img src={CrossIcon} alt="Remove filter"/>
                                    </Cross>
                                    Member discount
                                </FilterButton>
                            )
                        }

                        return (
                          <React.Fragment key={index}>
                              {(filter[0] !== "propertyType" && filter[0] !== "guestRating" && filter[0] !== "stars" && filter[0] !== "price" && filter[1]) &&
                                  <FilterButton onClick={() => toggleFilter('appliedFilters', filter[0])}>
                                      <Cross>
                                          <img src={CrossIcon} alt="Remove filter"/>
                                      </Cross>
                                      {filter[0] === "wheelchair" ? "Wheelchair accessible" :
                                          filter[0] === "hotTub" ? "Hot tub" :
                                              filter[0] === "petFriendly" ? "Pet friendly" :
                                                  filter[0] === "refundable" ? "Fully refundable" :
                                                    filter[0] === "breakfastIncluded" ? "Breakfast included" :
                                                      filter[0] === "familyFriendly" ? "Family friendly" :
                                                        filter[0] === "airCon" ? "Air condition" :
                                                          filter[0] === "payAtProperty" ? "Reserve now, pay later" : filter[0]
                                      }
                                  </FilterButton>
                              }

                              {(filter[0] === "stars" && filter[1]) &&
                                  filter[1].map(function (star, index) {
                                      return (
                                          <FilterButton key={index} onClick={() => toggleFilter('appliedFilters', filter[0], star)}>
                                              <Cross>
                                                  <img src={CrossIcon} alt="Remove filter"/>
                                              </Cross>
                                              {star} star{star === 1 ? '' : 's'}
                                          </FilterButton>
                                      )
                                  })}

                            {(filter[0] === "guestRating" && filter[1]) &&
                                  <FilterButton key={index} onClick={() => toggleFilter('appliedFilters', filter[0])}>
                                      <Cross>
                                          <img src={CrossIcon} alt="Remove filter"/>
                                      </Cross>
                                      Rating: {filter[1] >=5 ? filter[1] : filter[1] + "+"}
                                  </FilterButton>
                              }

                              {(filter[0] === "price" && filter[1]) &&
                                  <React.Fragment>
                                    {!(allFilters['price'][0] !== 0) && (allFilters['price'][1] !== 1000) &&
                                      <FilterButton key={index} onClick={() => toggleFilter('appliedFilters', filter[0])}>
                                        <Cross>
                                          <img src={CrossIcon} alt="Remove filter"/>
                                        </Cross>
                                        Price £{filter[1][0].toLocaleString()} to £{filter[1][filter[1].length - 1].toLocaleString() === "1,000" ? filter[1][filter[1].length - 1].toLocaleString() + "+" : filter[1][filter[1].length - 1].toLocaleString()}
                                      </FilterButton>
                                    }
                                  </React.Fragment>
                              }

                              {(filter[0] === "propertyType" && filter[1]) &&
                                  <FilterButton onClick={() => toggleFilter('propertyType', filter)}>
                                      <Cross>
                                          <img src={CrossIcon} alt="Remove filter"/>
                                      </Cross>
                                      <div>
                                          {filter[1] === "vr" ? "Apartments & rentals" : filter[1]}
                                      </div>
                                  </FilterButton>
                              }
                          </React.Fragment>
                        )
                    })}
                </Buttons>
            </ButtonsContainer>
        </React.Fragment>
    )
}

export default AppliedFilters;