import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Card from "./Card";
import SearchIcon from "../assets/icons/search.svg";


const List = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
`;

const SeeAll = styled.div`
    display: flex;
    justify-content: center;
    margin: 1.25rem 0;
    border: 1px solid var(--primary);
    background: var(--white);
    color: var(--primary);
    font-family: Montserrat,sans-serif;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 700;
    border-radius: 4px;
    transition: all .2s ease-in-out;
    
    &:hover {
        background: var(--primary);
        color: var(--white);
    }
`;

const NoResultsContainer = styled.div`
  background: var(--smokeGrey);
  padding: 3rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.25rem;
  
  h3 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.2;
    margin: 1.125rem 0;
  }
`;

const Search = styled.div`
  background: var(--white);
  border-radius: 3rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardList = ({properties, checkin, checkout, rooms, allFilters, isPayAtProperty, region, breakfastIncluded, searchInNewTab}) => {
    //manage default dates
    let thisFriday = new Date();
    const day = thisFriday.getDay();
    const targetDay = 5;  // Friday
    let dayOffset = targetDay - day;
    if (dayOffset < 0) dayOffset += 7;
    
    thisFriday = new Date(thisFriday.getTime() + (dayOffset * 24 * 3600 * 1000));

    let thisMonday = new Date(thisFriday);
    let numberOfDayToAdd = 3;
    thisMonday.setDate(thisMonday.getDate() + numberOfDayToAdd );

    thisFriday = thisFriday.toISOString().split('T')[0];
    thisMonday = thisMonday.toISOString().split('T')[0];

    if(!checkin || checkin === "0001-01-01") {
        checkin = thisFriday;
        checkout = thisMonday;
    }


    const date1 = new Date(checkin);
    const date2 = new Date(checkout);
    const diffTime = Math.abs(date2 - date1);
    const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    let featuresList = {};

    if (!breakfastIncluded) {
        featuresList['breakfast'] = <React.Fragment>Breakfast available</React.Fragment>;
    }

    rooms = rooms ? rooms : [{adults: 2}]
    if (typeof rooms === 'string') {
        rooms = JSON.parse(rooms);
    }

    function handleRegionForUrl(val) {
        return val.replace(/\.|\,|\'/g, "").replace(/\&/g, "and").replace(/ /g, "-");
      }

    return (
        <List>
        {properties.length > 0 ? properties.map(function (property, index) {
            //if multiroom and bcom send to deeplink
            let url;
            let app = (process.env.GATSBY_APP_ENV || "affiliate-site");
            if(rooms.length > 1 && property.bookingComDeeplink) {
                url = property.bookingComDeeplink + "&label=LRv2-"
            } else {
                url = "/hotel/" + handleRegionForUrl(property.property.urlRegion ? property.property.urlRegion.toLowerCase() : "united-kingdom") +"/" + property.property.slug + "/";
                url = url + "?checkin=" + checkin + "&checkout=" + checkout + "&nights=" + nights + "&rooms=" + JSON.stringify(rooms) + "&sourceToken=" + property.sourceToken + "&source=" + (property.bookingComDeeplink ? "bcom" : "expedia") + "&noSearchForm";
                    if (isPayAtProperty) url = url + "&payAtProperty=true"
            }
            
            let utmParams = `&utm_source=${app}&utm_medium=affiliate-site`;
            url ="https://www.laterooms.com/" + url + utmParams

            return (
            <Card 
              property={property}
              featuresList={featuresList}
              breakfastIncluded={allFilters.appliedFilters['breakfastIncluded']}
              url={url}
            />)
            })
            :
            null
        }
        {properties.length > 0 ? 
        <SeeAll onClick={(e) => searchInNewTab(e)}>See all hotels in {region}</SeeAll>
        :
        <NoResultsContainer>
            <Search>
                <img src={SearchIcon} alt="Search"/>
            </Search>
            <h3>No matches</h3>
            <p>Try removing filters.</p>
        </NoResultsContainer>
        }
        </List>
    )
}

export default CardList;