import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Slider from 'rc-slider';
import breakpoints from "../assets/styles/breakpoints";
import { Col, Row } from "styled-bootstrap-grid";
import MapStaticImage from '../assets/images/MapStaticImage.jpeg'
import MapMarker from '../assets/icons/MapMarker.svg'
import 'rc-slider/assets/index.css';
import AppliedFilters from "./AppliedFilters";
import TickIcon from "../assets/icons/tick.svg";
import CrossIcon from "../assets/icons/close.svg";
import ArrowDown from "../assets/icons/arrow-down.svg";
import FilterIcon from "../assets/icons/filter.svg";
import PinIcon from "../assets/icons/location-pin.svg";
import SortIcon from "../assets/icons/sort.svg"
import SortModal from "./SortModal.js";


import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const FiltersDesktop = styled.div`
  max-width: 18rem;
  margin: 0 1rem 0 0;
  display: none;
  
  &.map {
    width: 13rem;
    border-radius: 0.25rem;
    background-color: var(--white);
    padding: 0.75rem 1.75rem 0.75rem 0.75rem;
  }
  
  @media(min-width: ${breakpoints.md}) {
    display: block;
    top: 1rem;
    overflow-y: auto;
    padding-right: 0.5rem;
    width: 28rem;
  }
`;

const Header = styled.h4`
  font-weight: 600;
  margin: 0;
  position: relative;
  font-size: 1rem;
  line-height: 1.2;
  padding-bottom: 0.5rem;
  
  @media(min-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

const ArrowButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background: var(--lightenPrimary);
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 1.25rem;
  transform: ${props => props.show ? "rotate(180deg)" : "rotate(0deg)"};
  transition: .25s;
`;

const SubHeader = styled.span`
  font-size: 1rem;
  line-height: 1.2;
  color: var(--sharkGrey);
`;

const ClearFiltersMobile = styled.button`
  margin: 0;
  z-index: 1;
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1rem;
  bottom: 0.625rem;
  
  &:disabled {
    background: #80808061;
    opacity: 0.7;
  }
`;

const DoneMobile = styled.button`
  margin: 0 0 0 0.675rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  bottom: 0.625rem;
  left: 9.875rem;
  z-index: 1;
  display: inline-block;

  &:disabled {
    background: #80808061;
    opacity: 0.7;
  }
`;

const FilterBlock = styled.div`
  padding: 1.25rem 0;
  border-bottom: 1px solid var(--borderGrey);

  .count-copy {
    margin-top: .188rem;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.2;
    margin-bottom: 0;
  }

  ul {
    margin: .5rem 0 0;
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      margin-bottom: 0.625rem;
      
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .radio input {
      width: 0;
      margin-right: 1.563rem;
  }

  input {
    position: relative;
    width: 1.563rem;
    min-width: 1.563rem;
    height: 1.563rem;
    margin: 0;
    cursor: pointer;

    &:before {
      position: relative;
      display: block;
      width: 1.563rem;
      height: 1.563rem;
      border: 1px solid var(--borderGrey);
      content: "";
      background: #FFF;
    }

    &:after {
      position: absolute;
      display: block;
      left: 0.25rem;
      top: 0.25rem;
      width: 1.125rem;
      height: 1.125rem;
      content: "";
    }

    &:checked {
      &:before {
        border-color: var(--black);
      }

      &:after {
        background-image: url(${TickIcon});
        background-size: 100% 100%;

      }
    }
    
    &.radio {

        &:before {
            border-radius: 3.125rem;
            margin-top: -1px;
        }
    }
  }

  label {
    font-size: 0.875rem;
    margin-left: 0.625rem;
    cursor: pointer;
    max-width: 11rem;
  }
`;

const SliderContainer = styled.div`
  padding: .75rem 0.125rem 0 0.125rem;
  margin: 0 0.35rem;
`;

const SliderStyled = styled(Slider)`
    &.guest-rating {

        .rc-slider-rail {
            background-color: var(--primary);
        }
    }

  .rc-slider-rail {
    height: 0.25rem;
  }

  .rc-slider-track {
    background-color: var(--primary);
    height: 0.25rem;
  }

  .rc-slider-handle {
    border: 3px solid var(--primary);
    opacity: 1;
    
    &:active {
      box-shadow: 0 0 5px var(--primary);
    }
    
    &:focus-visible {
      box-shadow: 0 0 0 3px var(--primary);
    }
  }

  .rc-slider-handle-dragging {
    border-color: var(--primary) !important;
    box-shadow: 0 0 0 0.25rem var(--primary) !important;
  }
`;

const Stars = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.625rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;
`;

const Star = styled.div`
  position: relative;
  height: 1.875rem;
  width: 2.813rem;
  
  input {
    border: none;
    background-color: var(--smokeGrey);
    border-radius: 0.625rem;
    font-size: 0.75rem;
    color: var(--darkGrey);
    font-weight: 600;
    height: 1.875rem;
    width: 2.813rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    
    &:before {
      content: "";
      display: none;
    }
    
    &:after {
      content: "";
      border: none;
      background-color: var(--moreLighterPrimary);
      border-radius: 0.625rem;
      font-size: 0.75rem;
      color: var(--darkGrey);
      font-weight: 600;
      height: 1.875rem;
      width: 2.813rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      top: 0;
      left: 0;
    }

    &:checked {
      &:before {
        
      }

      &:after {
        border: 2px solid var(--primary);
        background-image: none;
      }
    }
  }
  
  label {
    position: absolute;
    font-size: 0.75rem;
    color: var(--sharkGrey);
    font-weight: 600;
    top: calc(50% - 0.5rem);
    left: 50%;
    margin: 0px;
    transform: translateX(-50%);
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: .5rem;
`;

const MapLabel = styled.div`
  width: 100%;
  height: 3rem;
  background-color: var(--lightenPrimary);
  color: var(--primary);
  position: absolute;
  bottom: 0;
  font-weight: bold;
  line-height: 3rem;
  text-align: center;
  cursor: zoom-in;
  position: absolute;
`;

const FacilitiesBlock = styled.div`
  display: ${props => props.show ? "block" : "none"};
`;

const MobileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.625rem 0;
  border-top: 1px solid var(--borderGrey);
  border-bottom: 1px solid var(--borderGrey);
  margin-bottom: 1.25rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  position: sticky;

  @media(min-width: ${breakpoints.md}) {
    display: none;
  }

`;

const MobileButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
`;

const MobileButton = styled.button`
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--black);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  background: transparent;
  font-weight: 400;
  position: relative;
  border-radius: 0;
  
  img {
    height: 1rem;
    width: 1rem;
    margin-right: 6px;
  }

  &:last-of-type {
    border: none;
    padding-right: 0;
    margin-left: 0.625rem;
  }

  &:hover, &:active, &:focus {
    background: transparent;
    
  }

  border: none;
`;

const MobileBody = styled.div`
  left: ${props => props.visible ? "0" : "-100vw"};
  height: 100vh;
  background: var(--white);
  position: fixed;
  top: 0;
  transition: .3s;
  width: 100%;
  z-index: 4;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const MobileContainer = styled.div`
  position: relative;
  padding: 1.25rem 1.25rem 12rem 1.25rem;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 0.125rem;
  top: 1.25rem;
  background: transparent;
  padding: 1rem;
  border: none;
  
  img {
    height: 1rem;
    width: 1rem;
  }
  
  &:hover, &:focus, &:active {
    background: transparent;
  }
`;

const MobileFilters = styled.div`
  display: ${props => props.show ? "block" : "none"};
  position: sticky;
`;

const FiltersAmount = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 1.25rem;
  background: var(--primary);
  color: var(--white);
  font-size: 0.625rem;
  line-height: 0.625rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
`;

const MapConatainer = styled.div`
  position: relative;
  height: 10rem;
  border-radius: 0.25rem;
  overflow: hidden;
`;

const MapButton = styled.button`
  display: block;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 12px;
  padding: 0.5rem 0.75rem;
  border: none;
  background: var(--primary);
  color: var(--white);
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 700;
  border-radius: 4px;
  -webkit-transition: background .2s ease-in-out;
  transition: background .2s ease-in-out;
  &:hover {
    background-color: var(--lightenPrimary);
  }
  
  @media(min-width: ${breakpoints.md}) {
    top: 80%;
    left: 50%;
    font-size: 0.875rem;
    white-space: nowrap;
    padding: 0.625rem 0.875rem;
    transform: translate(-50%, -50%);
  }
`;

const MapImageMarker = styled.img`
  cursor: pointer;
  @media(min-width: ${breakpoints.md}) {
    position: absolute;
    width: 2.5rem;
    left: 50%;
    top: 40%;
    transform: translate(-50%,-50%);
  }
`

const MapImage = styled.img`
  cursor: zoom-in;
`;

const StyledRadioGroup = styled(RadioGroup)`
  label {
    margin: 0;

    .MuiTypography-root {
      font-size: 0.875rem;
      line-height: 1.3;
      font-family: Montserrat, sans-serif;
    }
    
    .MuiButtonBase-root {
      padding-right: .375rem;
      
      .PrivateSwitchBase-input {
        margin: 0;
        width: 100%;
        height: 100%;
        cursor: inherit;
        position: absolute;
      }

      &.Mui-checked {
        color: var(--primary);
      }
      
      &:hover {
        color: var(--primary);
        background: transparent;
      }
    }
  }
  margin-left: -2rem;
`;

const MobileFixed = styled.div`
    width: 100%;
    background: white;
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1.25rem;

    button {
      border: none;
      background: var(--primary);
      color: var(--white);
      font-family: Montserrat,sans-serif;
      padding: 1rem;
      font-size: 1rem;
      cursor: pointer;
      font-weight: 700;
      border-radius: 4px;
      transition: background .2s ease-in-out;
  
      &:hover {
        background: var(--lightenPrimary);
      }
    }
`;

const PriceFilter = ({allFilters, setSliderValue, setSliderValueAfter, isDesktop, handleMobileFilters}) => {
  let priceValue = []

  if (allFilters.appliedFilters.price && allFilters['price'] === [0,1000]) {
      priceValue = [allFilters.appliedFilters.price[0], allFilters.appliedFilters.price[allFilters.appliedFilters.price.length - 1]];
  } else if (allFilters['price'] !== [0,1000]) {

      priceValue = allFilters['price'];
  }


  return (
      <FilterBlock>
          <Header>Price per night</Header>
          <SubHeader>£{priceValue[0].toLocaleString()} - £{priceValue[1].toLocaleString()}{priceValue[1] === 1000 && "+"}</SubHeader>
          <SliderContainer>
              <SliderStyled
                  min={0}
                  max={1000}
                  step={20}
                  range={true}
                  value={priceValue}
                  allowCross={false}
                  reverse={false}
                  aria-label="Price slider"
                  defaultValue={50}
                  onChange={(e) => setSliderValue(e, 'price')}
                  onAfterChange={(e)=>{setSliderValueAfter(e, 'price') }}
              />
          </SliderContainer>
      </FilterBlock>
  )
}

const SideFilters = ({
  regionLonLat,
  region,
  allFilters,
  toggleFilter,
  popularFilters,
  setSliderValue,
  setSliderValueAfter,
  toggleSortMobile,
  handleClearFilters,
  clearFiltersMobile,
  setGuestRating,
  setMapModalVisible,
  isMapModalVisible,
  handleMobileFilters,
  showMobileFilters,
  setShowMobileFilters,
  applyMobileFilters,
  toggleMemberDiscount,
  showMapWithFilter,
  isMap,
  isModal,
  handleSortModal,
  isShowSortModal,
  redirectToSearch
}) => {

    const [isDesktop, setIsDesktop] = useState(false);
    const [showMobileSort, setShowMobileSort] = useState(false);
    const [showFacilities, setShowFacilities] = useState(true);


    let appliedFiltersArr = Object.values(allFilters.appliedFilters); 
    let anyFiltersApplied = !appliedFiltersArr.every(element => {
        if (element === 'default' || element === 'guestrating_desc' || element === 'price_asc' || element === 'price_desc' || element === 'memberdiscount') {
            return true;
        }
        return !element
    });

    useEffect(() => {
        const onResize = () => {
            if (window.innerWidth >= 768 && isDesktop === false) {
                setIsDesktop(true);
            } else if (window.innerWidth < 768 && isDesktop === true) {
                setIsDesktop(false);
            }
        }

        document.body.style.overflow = 'visible'

        if (typeof window !== "undefined") {
            onResize();
            window.addEventListener("resize", onResize)
        }
    }, [isDesktop])

    if (typeof document !== "undefined" ) {
      if (isDesktop) {
          document.body.style.overflow = "auto";
      } else {
          if (showMobileSort || showMobileFilters) {
              document.body.classList.add('overflow-filters');
          } else {
              document.body.classList.remove('overflow-filters');
          }
      }
    }

    const stars = [
        {
            value: 1
        },
        {
            value: 2
        },
        {
            value: 3
        },
        {
            value: 4
        },
        {
            value: 5
        },
    ];

    const propertyTypes = [
        // {
        //     label: "Castle",
        //     value: "castle"
        // },
        // {
        //     label: "Bed & breakfast",
        //     value: "bed&breakfast"
        // },
        // {
        //     label: "Apartment",
        //     value: "apartament"
        // },
        // {
        //     label: "Apart-hotel",
        //     value: "apart-hotel"
        // },
        // {
        //     label: "Hotel resort",
        //     value: "hotel-resort"
        // },
        // {
        //     label: "Hostel/Backpacker accommodation",
        //     value: "hostel"
        // },
        // {
        //     label: "Condo",
        //     value: "condo"
        // }
        {
            label: "Hotel",
            value: "hotel"
        },
        {
            label: "Apartments & rentals",
            value: "vr"
        },
    ];

    const facilities = [
        {
            label: "Wifi",
            value: "wifi"
        },
        {
            label: "Restaurant",
            value: "restaurant"
        },
        {
            label: "Pool",
            value: "pool"
        },
        {
            label: "Parking",
            value: "parking"
        },
        {
            label: "Gym",
            value: "gym"
        },
        {
            label: "Wheelchair",
            value: "wheelchair"
        },
        {
            label: "Family friendly",
            value: "familyFriendly"
        },
        {
            label: "Pet friendly",
            value: "petFriendly"
        },
        {
            label: "Hot tub",
            value: "hotTub"
        },
        {
            label: "Air condition",
            value: "airCon"
        },
        {
            label: "Spa",
            value: "spa"
        },
        {
            label: "Breakfast included",
            value: "breakfastIncluded"
        },
    ];


const PopularFilters = ({popularFilters, toggleFilter, allFilters, handleMobileFilters, isDesktop}) => {
  return (
      <FilterBlock>
          <Header>Popular filters</Header>
          <ul >
              {popularFilters.map((filter, index) => {
                  return (
                      <li key={index}>
                          <input type="checkbox"
                                 id={filter.value}
                                 name={filter.value}
                                 value={filter.value}
                                 checked={allFilters ? allFilters.appliedFilters ? allFilters.appliedFilters[filter.value] : false : false}
                                 onChange={(e)=>{ toggleFilter('popular', filter) }}
                          />
                          <label htmlFor={filter.value}>{filter.label}</label>
                      </li>
                  )
              })}
          </ul>
      </FilterBlock>
  )
}

const StarFilter = ({stars, toggleFilter, allFilters, isDesktop, handleMobileFilters}) => {
  return (
      <FilterBlock>
          <Header>Star rating</Header>
          <Stars>
              {stars.map((star, index) => {
                  return (
                      <Star key={index}>
                          <input 
                              type="checkbox" 
                              id={star.value} 
                              name={stars.value} 
                              value={star.value} 
                              checked={allFilters ? allFilters.appliedFilters['stars'] ? allFilters.appliedFilters['stars'].includes(star.value) : false : false}
                              onChange={(e)=>{toggleFilter('stars', star)}}
                          />
                          <label htmlFor={star.value}>{star.value}</label>
                      </Star>
                  )
              })}
          </Stars>
      </FilterBlock>
  )
}

const GuestRating = ({allFilters, setGuestRating, handleMobileFilters, isDesktop }) => {
  let localValue = allFilters['guestRating'] ? allFilters['guestRating'] : allFilters.appliedFilters.guestRating;

  if (!localValue) {
      localValue = 0;
  }

  const rating = [
      {
          value: 0,
          label: "Any"
      },
      {
          value: 9,
          label: "Exceptional 9+"
      },
      {
          value: 8,
          label: "Very good 8+"
      },
      {
          value: 7,
          label: "Good 7+"
      },
  ]

  return (
      <FilterBlock>
          <Header>Guest rating</Header>
          <FormControl>
              <StyledRadioGroup defaultValue={localValue} name="radio-buttons-group"
                                onChange={(e, value)=>{setGuestRating(value, 'guestRating') }}
              >
                  {rating.map((item, index) => {
                      return (
                          <FormControlLabel
                              value={item.value}
                              key={index}
                              label={item.label}
                              control={<Radio />}/>
                      )
                  })}
              </StyledRadioGroup>
          </FormControl>
      </FilterBlock>
  )
}

const PropertyTypeFilter = ({propertyTypes, toggleFilter, allFilters, isDesktop, handleMobileFilters}) => {
  return (
      <FilterBlock>
          <Header>Property type</Header>
          <ul>
              {propertyTypes.map((property, index) => {
                  return (
                      <li key={index}>
                          <input type="checkbox"
                                 onChange={(e)=>{toggleFilter('propertyType', property) }}
                                 value={property.value}
                                 name={property.value}
                                 id={property.value}
                                 checked={allFilters ? allFilters.appliedFilters ? allFilters.appliedFilters['propertyType'] === property.value : false : false}
                          />
                          <label htmlFor={property.value}>{property.label}</label>
                      </li>

                  )
              })}
          </ul>
      </FilterBlock>
  )
}

const FreeCancellationFilter = ({toggleFilter, allFilters, isDesktop, handleMobileFilters }) => {
  const payAtProperty = 
    {
      label: "Reserve now, pay later",
      value: "payAtProperty"
    }

  return (
      <FilterBlock>
          <Header>Payment type</Header>
          <Block>
              <input 
              type="checkbox" 
              id="free-cancellation" 
              name="free-cancellation"
              checked={allFilters ? allFilters.appliedFilters['refundable'] : false }
              onChange={(e)=>{ toggleFilter('free-cancellation', null) }}
              />
              <label htmlFor="free-cancellation">Fully refundable</label>
          </Block>
          <Block>
              <input 
              type="checkbox" 
              id="payAtProperty" 
              name="payAtProperty"
              checked={allFilters ? allFilters.appliedFilters['payAtProperty'] : false }
              onChange={(e)=>{ toggleFilter('popular', payAtProperty) }}
              />
              <label htmlFor="payAtProperty">Reserve now, pay later</label>
          </Block>
      </FilterBlock>
  )
}

const MemberDiscountFilter = ({toggleMemberDiscount, allFilters}) => {
  return (
      <FilterBlock>
          <Header>Member discount</Header>
          <Block>
              <input type="checkbox" id="member-discount"
                     onChange={() => toggleFilter('memberRate')}
                     checked={allFilters ? allFilters.appliedFilters.membersDiscount : false}
              />
              <label htmlFor="member-discount">Show only properties with member discounts</label>
          </Block>
      </FilterBlock>
  )
}

const FacilitiesFilter = ({setShowFacilities, showFacilities, toggleFilter, facilities, allFilters, isDesktop, handleMobileFilters}) => {
  return (
      <FilterBlock>
          <Header onClick={() => setShowFacilities(!showFacilities)}>
              Facilities
              <ArrowButton show={showFacilities === true ? 1 : 0}>
                  <img src={ArrowDown} alt={showFacilities ? "Hide facilities" : "Show facilities"}/>
              </ArrowButton>
          </Header>
          <FacilitiesBlock show={showFacilities}>
              <ul>
                  {facilities.map((facility, index) => {
                      return (
                          <li key={index}>
                              <input type="checkbox"
                                     onChange={(e)=>{toggleFilter('facility', facility) }}
                                     id={"facility"+facility.value}
                                     name={facility.value}
                                     value={facility.value}
                                     checked={allFilters ? allFilters.appliedFilters? allFilters.appliedFilters[facility.value] : false : false}
                              />
                              <label htmlFor={"facility"+facility.value}>{facility.label}</label>
                          </li>
                      )
                  })}
              </ul>
          </FacilitiesBlock>
      </FilterBlock>
  )
}

    return (
        <React.Fragment>
                <FiltersDesktop className={isMap ? 'map': ''} isModal={isModal}>
                    {!isMap && <MapConatainer>
                        <MapButton onClick={() => redirectToSearch(null, null, true)}>View in map</MapButton>
                        <MapImage
                            onClick={() => redirectToSearch(null, null, true)}
                            src={MapStaticImage}
                            alt={`${region} map`}
                          />
                        <MapImageMarker src={MapMarker} alt="marker" onClick={() => redirectToSearch(null, null, true)}></MapImageMarker>
                        <MapButton onClick={() => redirectToSearch(null, null, true)}>Show on map</MapButton>
                    </MapConatainer>}
                    {anyFiltersApplied
                        &&
                        <FilterBlock>
                            <AppliedFilters
                                toggleFilter={toggleFilter}
                                allFilters={allFilters}
                                setSliderValue={setSliderValue}
                                setSliderValueAfter={setSliderValueAfter}
                                handleClearFilters={handleClearFilters}
                                toggleMemberDiscount={toggleMemberDiscount}
                            />
                        </FilterBlock>
                    }
                    <PopularFilters popularFilters={popularFilters} toggleFilter={toggleFilter} allFilters={allFilters}/>
                    <PriceFilter allFilters={allFilters} setSliderValue={setSliderValue} setSliderValueAfter={setSliderValueAfter}/>
                    <MemberDiscountFilter toggleMemberDiscount={toggleMemberDiscount} allFilters={allFilters}/>
                    <StarFilter stars={stars} toggleFilter={toggleFilter} allFilters={allFilters}/>
                    <GuestRating allFilters={allFilters} setGuestRating={setGuestRating}/>
                    <PropertyTypeFilter propertyTypes={propertyTypes} toggleFilter={toggleFilter} allFilters={allFilters}/>
                    <FreeCancellationFilter toggleFilter={toggleFilter} allFilters={allFilters} />
                    <FacilitiesFilter setShowFacilities={setShowFacilities} showFacilities={showFacilities} toggleFilter={toggleFilter} facilities={facilities} allFilters={allFilters}/>
                </FiltersDesktop>
                
                <React.Fragment>
                    <MobileHeader>
                        <MobileButtons>
                            <MobileButton onClick={()=> handleSortModal()}>
                                <img src={SortIcon} alt="Sort icon" loading="lazy"/> Sort 
                            </MobileButton>
                            <MobileButton mid onClick={() => redirectToSearch(null, null, true)}>
                                <img src={PinIcon} alt="Map icon" loading="lazy"/> Map
                            </MobileButton>
                            <MobileButton onClick={() => setShowMobileFilters(!showMobileFilters)}>
                                <img src={FilterIcon} alt="Filter icon" loading="lazy"/> Filters
                                {Object.values(allFilters.appliedFilters).filter(value => {
                                    // for sort
                                    if (value === 'default' || value === 'guestrating_desc' || value === 'price_asc' || value === 'price_desc' || value === 'memberdiscount') {
                                        return null
                                    }
                                    return !!value
                                }).length > 0 &&
                                    <FiltersAmount>
                                        {Object.values(allFilters.appliedFilters).filter(value => !!value).length -1}
                                    </FiltersAmount>
                                }
                            </MobileButton>
                        </MobileButtons>
                    </MobileHeader>

                    <MobileBody visible={showMobileSort || showMobileFilters}>
                            <Row>
                                <Col lg={10} lgOffset={1}>
                                    <MobileContainer>
                                        <MobileFilters show={showMobileFilters}>
                                          <MobileFixed>
                                            <ClearFiltersMobile onClick={clearFiltersMobile} disabled={(allFilters['facility'].length + allFilters['property'].length + allFilters['stars'].length + (allFilters['free-cancellation'] === true ? 1 : 0) + ((allFilters['price'][0] !== 0) || (allFilters['price'][1] !== 1000) ? 1 : 0) + ((allFilters['guestRating'][0] !== 0) || (allFilters['guestRating'][1] !== 10) ? 1 : 0)) === 0}>Clear filters</ClearFiltersMobile>
                                            <DoneMobile onClick={() => applyMobileFilters()}>Done</DoneMobile>
                                          </MobileFixed>
                                          <PopularFilters popularFilters={popularFilters} toggleFilter={toggleFilter} isDesktop={isDesktop}  allFilters={allFilters}/>
                                          <PriceFilter isDesktop={isDesktop}  allFilters={allFilters} setSliderValue={setSliderValue} setSliderValueAfter={setSliderValueAfter}/>
                                          <MemberDiscountFilter toggleMemberDiscount={toggleMemberDiscount} allFilters={allFilters}/>
                                          <StarFilter stars={stars} allFilters={allFilters}  toggleFilter={toggleFilter} isDesktop={isDesktop}/>
                                          <GuestRating allFilters={allFilters} setGuestRating={setGuestRating}  isDesktop={isDesktop} />
                                          <PropertyTypeFilter  isDesktop={isDesktop} propertyTypes={propertyTypes} toggleFilter={toggleFilter} allFilters={allFilters} setSliderValueAfter={setSliderValueAfter}/>
                                          <FreeCancellationFilter allFilters={allFilters} toggleFilter={toggleFilter}  isDesktop={isDesktop} />
                                          <FacilitiesFilter  setShowFacilities={setShowFacilities} isDesktop={isDesktop} showFacilities={showFacilities} toggleFilter={toggleFilter} facilities={facilities} allFilters={allFilters}/>
                                        </MobileFilters>
                                        <CloseButton onClick={(showMobileSort === true) ? (() => setShowMobileSort(!showMobileSort)) : (() => window.location.reload())}>
                                            <img src={CrossIcon} loading="lazy" alt="Close"/>
                                        </CloseButton>
                                    </MobileContainer>
                                </Col>
                            </Row>
                    </MobileBody>

                </React.Fragment>
            
            {isShowSortModal !== null && <SortModal handleSortModal={handleSortModal} isShowSortModal={isShowSortModal} toggleSortMobile={toggleSortMobile} />}
        </React.Fragment>
    )
}

export default SideFilters;