import React from "react";
import styled from "styled-components";
import breakpoints from "../assets/styles/breakpoints";
import Select from 'react-select';
import ArrowDown from '../assets/icons/arrow-down.svg';

const FilterBlock = styled.div`
  margin: 0 0 1.25rem 0;
  display: flex;
  justify-content: space-between;

  p {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 0;
  }

  .select {
    position: relative;
    cursor: pointer;
    width: 92%;
    height: max-content;

    &::after {
        content: "Sort by:";
        position: absolute;
        font-weight: 600;
        margin: 0;
        font-size: 0.875rem;
        left: 0.8rem;
        top: 0.5rem;
        pointer-events: none;
    }
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    .select {
        margin: initial;
        width: 250px;
    }
  }
`;

const Header = styled.h4`
  font-weight: 600;
  margin: 0 1.25rem .5rem 0;
  position: relative;
  font-size: 0.875rem;
  line-height: 1rem;
`;

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: "0.25rem",
        border: "1px solid #E0E0E0",
        background: "#F5F4F3",
        boxShadow: "none",
        cursor: "pointer",
        width:  "100%",
        ':hover': {
            borderColor: "#E0E0E0",
        },
    }),
    input: styles => ({
        ...styles,
        padding: "0",
        color: "#484847",
        fontSize: "0.875rem",
        fontFamily: "'Montserrat', Helvetica, Arial",
    }),
    // selected option
    singleValue: styles => ({
        ...styles,
        color: "#484847",
        fontSize: "0.875rem",
        fontFamily: "'Montserrat', Helvetica, Arial",
        marginTop: ".5rem",
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "var(--primary)" : "#000000",
        fontWeight: state.isSelected ? "600" : "500",
        backgroundColor: "#FFFFFF",
        fontSize: '0.875rem',
        lineHeight: '1rem',
        ':hover': {
            color: 'var(--primary)',
            backgroundColor: 'var(--smokeGrey)',
        },
    }),
    valueContainer: (styles) => ({
        ...styles,
        paddingTop: "0",
        paddingLeft: ".625rem",
        paddingRight: ".625rem",
        height: "50px",
        color: "#484847",
    }),
    placeholder: (styles) => ({
        ...styles,
        color: "#484847",
        fontSize: "0.875rem",
        fontFamily: "'Montserrat', Helvetica, Arial",
    }),
    menu: styles => ({
        ...styles,
        fontSize: "0.875rem",
        lineHeight: '1rem',
        border: "1px solid #E0E0E0",
        borderRadius: "0.25rem",
        marginBottom: '0',
        borderBottom: 'none',
        boxShadow: 'none',
    }),
    indicatorSeparator: styles => ({...styles, display: "none"}),
    dropdownIndicator: styles => ({...styles, display: "none"}),
    indicatorsContainer: (provided) => ({
        ...provided,
        background: "url(" + ArrowDown + ") no-repeat",
        backgroundSize: "26px 48px",
        backgroundPosition: "right 6px center",
        width: "40px",
        height: "50px",
    }),
};

const Sort = ({totalCount, allFilters, region, toggleSortMobile, isDesktop, showMob, isMap}) => {
    const sortFilters = [
        {
            label: "Most popular",
            value: "default"
        },
        {
            label: "Guest rating",
            value: "guestrating_desc"
        },
        {
            label: "Lowest price",
            value: "price_asc"
        },
        {
            label: "Highest price",
            value: "price_desc"
        }
    ];

    const detectDefault = function() {
        if (allFilters) {

        switch (true) {
            case allFilters.appliedFilters['sort'] === "default":
                return(
                    {
                        label: "Most popular",
                        value: "default"
                    }
                )
            break;
            case allFilters.appliedFilters['sort'] === "guestrating_desc":
                return(
                    {
                        label: "Guest rating",
                        value: "guestrating_desc"
                    }
                )
            break;
            case allFilters.appliedFilters['sort'] === "price_asc":
                return(
                    {
                        label: "Lowest price",
                        value: "price_asc"
                    }
                )
            break;
            case allFilters.appliedFilters['sort'] === "price_desc":
                return(
                    {
                        label: "Highest price",
                        value: "price_desc"
                    }
                )
            break;
            case allFilters.appliedFilters['sort'] === "memberdiscount":
                return(
                    {
                        label: "Members discount",
                        value: "memberdiscount"
                    }
                )
            break;
            default:
                return({
                    label: "Most popular",
                    value: "default"
                })
                break;
            }
        }

    }

    const SortBy = ({sortFilters, toggleSortMobile}) => {
        return (
            <Select className="select"
                    name="sort"
                    id="sort"
                    options={sortFilters}
                    value={sortFilters.value}
                    isSearchable={false}
                    defaultValue={
                        detectDefault()
                    }
                    onChange={(e) => toggleSortMobile(e)}
                    // onInputChange={(e) => dangerouslySetInput(e)}
                    styles={customStyles} />
        )
    }

    return (
        <FilterBlock>
            {totalCount > 0 && !isMap && <div>
                <Header>{region}</Header>
                <p className="count-copy">{totalCount.toLocaleString()} {totalCount === 1 ? "property" : "properties"}</p>
            </div>}
            {(isDesktop || !showMob) &&
                <SortBy toggleSortMobile={toggleSortMobile} sortFilters={sortFilters} allFilters={allFilters}/>}
        </FilterBlock>

    )
}

export default Sort;