import React, {useState} from "react";
import styled from "styled-components";
import Select from 'react-select';
import CrossIcon from "../assets/icons/close.svg";

const SelectContainer = styled.div`
  height: 15rem;
  background-color: var(--white);
  width: 100vw;
  position: fixed;
  box-shadow: none;
  bottom: ${props => props.isShowSortModal ? '0' : '-15rem'};
  left: 0;
  animation: ${props => props.isShowSortModal ? "slideIn .3s ease-in-out" : "slideOut .3s ease-in-out"};

  // fix light blue background issue on React-select
  .css-d7l1ni-option {
    background-color: transparent;
  }

  @keyframes slideIn {
    0%{
        bottom: -15rem;
    }
    100% {
        bottom: 0;
    }
  }
  @keyframes slideOut {
    0%{
        bottom: 0rem;
    }
    100% {
        bottom: -15rem;
    }
  }
`

const Title = styled.div`
    position: absolute;
    z-index: 1;
    background: white;
    line-height: 3rem;
    width: 100%;    
    box-sizing: border-box;
    font-weight: bold;
    font-size: 1.25rem;
    padding-left: 1rem;
`

const BG = styled.div`
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vh; 
  background-color: rgba(0,0,0,0.5);
`

const CloseButton = styled.button`
  position: absolute;
  z-index: 1; 
  top: 0;
  right: 0.25rem;
  background: transparent;
  border: none;
  padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 700;
  
  img {
    height: 1rem;
    width: 1rem;
  }
  
  &:hover, &:focus, &:active {
    background: transparent;
  }
`;

const SortModal = ({handleSortModal,isShowSortModal, toggleSortMobile}) => {
    const [menuOpen, setMenuOpen] = useState(true);

    const sortFilters = [
        {
            label: "Most popular",
            value: "default"
        },
        {
            label: "Guest rating",
            value: "guestrating_desc"
        },
        {
            label: "Lowest price",
            value: "price_asc"
        },
        {
            label: "Highest price",
            value: "price_desc"
        },
    ];


    const customStyles = {
        // the 'menu' key targets the dropdown menu
        menu: (provided) => ({
          ...provided,
          boxShadow: 'none',
          backgroundColor: 'transparent'
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? "var(--primary)" : "var(--black)",
          fontWeight: state.isSelected ? "600" : "500",
          backgroundColor: "var(--white)",
          fontSize: '0.875rem',
          lineHeight: '1rem',
          ':hover': {
              backgroundColor: '#41098717',
          },
      }),
      };

    // add "$" to isSHowSortModal to avoid React DOM error
    return (
        <>
        {isShowSortModal && <BG onClick={handleSortModal}/>}
        <SelectContainer isShowSortModal={isShowSortModal}>
        <Title>Sort by</Title>
        <Select
            options={sortFilters}
            styles={customStyles}
            value={sortFilters.value}
            menuIsOpen={menuOpen}
            onChange={(e) => toggleSortMobile(e)}
            />

        <CloseButton onClick={handleSortModal}>
            <img src={CrossIcon} alt="Close"/>
        </CloseButton>
        </SelectContainer>
        </>
    )
}

export default SortModal;