import React from 'react';
import styled from "styled-components";

const ReviewsRating = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000;
  margin-top: .35rem;
  
  p {
    margin: 0;
    font-size: 0.75rem;
    white-space: nowrap;
    color: #191B1B;
    
    b {
      border: none;
      background: var(--primary);
      color: #FFF;
      border-radius: 4px;
      padding: .2rem .65rem;
      box-sizing: border-box;
    }
  }
`;

const Rating = ({rating, comp, reviews}) => {

  return (
    <ReviewsRating className={comp === "reviews" ? "reviews" : ""}>
      <p>
        <b>
          {rating}{rating % 1 === 0 && ".0"}
        </b>
      </p>
      &nbsp;
      {reviews > 0 &&
        <p>{reviews.toLocaleString()} review{reviews > 1 && "s"}</p>
      }
    </ReviewsRating>
  )
}

export default Rating;