import React, {useState, useEffect, useRef} from "react";
import Rating from "./Rating";
import styled from "styled-components";
import breakpoints from "../assets/styles/breakpoints";
import Icon from "./Icon"
import Star from "../assets/icons/star.svg"
import HalfStar from "../assets/icons/half-star.svg"

const CardContainer = styled.a`     
  display: block;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  background: transparent;
  margin-bottom: 0.625rem;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid #D9D9D9;
  //change the first here for the users hotel they searched to appear heroed
  background: ${props => props.isUsersHotel ? "transparent" : "transparent"};
  color:  var(--sharkGrey);

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    margin-bottom: ${props => props.isMap ? "none" : "1.25rem"};
    padding: ${props => props.isMap ? ".5rem" : "none"};
    border-radius: .25rem;
    cursor: pointer;
    &:hover {
      border: 1px solid var(--primary);
      border: ${props => props.isMap ? "var(--primary)" : "none"};
      border-bottom:${props => props.isMap ? "var(--primary)" : "1px solid #D9D9D9"};
    }
  }
`;

const CardBlock = styled.div`  
  border: ${props => props.isUsersHotel || props.isHotelFirstResult ? "#EBE7F2" : "none"} solid 6px;
  display: flex;
  margin-bottom: 0;
  background-color: ${props => props.isUsersHotel || props.isHotelFirstResult ? "#EBE7F2" : ""};
  border-radius: 0.25rem;
`;

const Left = styled.div`
  width: 35%;
  position: relative;

  @media only screen and (min-width: ${breakpoints.xs}) {
    width: 40%;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    max-width: ${props => props.isMap ? "100%" : "12.5rem"};
  }
    
  @media only screen and (min-width: 1060px) {
    max-width: ${props => props.isMap ? "100%" : "12.5rem"};
  }
`;

const Right = styled.div`
  width: 65%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0 0 0 0.625rem;
  justify-content: space-between;

  @media only screen and (min-width: ${breakpoints.xs}) {
    width: 70%;
  }
    
  @media only screen and (min-width: ${breakpoints.md}) {
    justify-content: flex-start;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    padding: ${props =>  props.isMap ? "0 0 0 .5rem" : "0 0 0 1.25rem"};
  }

  @media only screen and (min-width: ${breakpoints.xl}) {
    padding:${props=> props.isMap ? "" : ".5rem 0 0 1.25rem"} ;
  }
`;

const Name = styled.div`
  font-size: 1rem;
  margin: 0 .75rem 0 0;
  color: var(--sharkGrey);
  text-decoration: none;
  font-weight: 600;
  line-height: 1.2;
  display: inline;

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${props=> props.isMap ? "1rem":"1.25rem"};
    margin-bottom: 0.625rem;
  }
`;

const Address = styled.p`
  line-height: 0.938rem;
  color: var(--sharkGrey);
`;

const PriceCopy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  min-width: 11rem;
  margin-top: 0.938rem;

  p {
    font-size: 0.875rem;
    line-height: 1rem;
    margin: 0;
    text-align: right;
  }
    
  span {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .price {
    font-size: 1.25rem;
    color: var(--sharkGrey);
    margin: 0 0 .188rem 0;
    
    b {
      &.multiple {
        font-weight: 200;
      }
    }
    
    del {
      color: var(--red);
      font-weight: 100;
      margin-right: 0.313rem;
      font-size: .875rem;
    }
  }
  
  .total {
    font-weight: 700;
    color: var(--sharkGrey);
  }
    
  @media only screen and (min-width: 1060px) {
    margin-top: 0;
  }
`;

const Copy = styled.div`
  p {
    font-size: 0.75rem;
    margin: 0;
  }
`;

const NoAvailCopy = styled.div`
  font-weight: bold;
  color: var(--red);
  font-size: 0.75rem;
`;

const RedCopy = styled.p`
  margin: 0;
  display: block;
  font-weight: 700;
  font-size: 0.75rem;
  color: var(--red);
`;

const GreenCopy = styled.p`
    margin: 0;
    display: block;
    font-weight: 700;
    font-size: 0.75rem;
    color: var(--green);
    
    &.dot {
      display: none;
    }
    
    @media only screen and (min-width: ${breakpoints.sm}) {
        &.dot {
            display: block;
        }
    }

    @media only screen and (min-width: 1060px) {
        &.dot {
            display: none;
        }
    }

    @media only screen and (min-width: ${breakpoints.xl}) {
        &.dot {
            display: block;
        }
    }
`;

const MemberBanner = styled.div`
  background-color: var(--yellow);
  color: var(--primary);
  width: max-content;
  border-radius: .25rem;
  padding: 0.25rem 0.4rem;
  box-sizing: border-box;
  font-weight: 700;
  font-size: .75rem;
  white-space: nowrap;
  cursor: pointer;
  transition: .3s;
  height: max-content;
  display: none;
  
  &.d-hide {
    display: block;
    margin-bottom: .625rem;
  }

  &:hover {
    background: #fdd45fd9;
    transition: .3s;
  }

  &.button {
    &:hover {
     text-decoration: underline;
    }
  }
    
  @media only screen and (min-width: 1060px) {
    display: ${props => props.isMap ? "none" : "block"};
      
    &.d-hide {
      display: ${props => props.isMap ? "block" : "none"};
    }
  }
`;

const Stars = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: help;
  
  p {
    font-size: 1rem;
    line-height: 1.2rem;
    margin-right: .25rem;
  }
  
  img {
    height: 1rem;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    position: relative;
  }
`;

const StarsInfo = styled.div`
  z-index: 1;
  padding: .5rem;
  width: 15.625rem;
  background: white;
  color: var(--sharkGrey);
  font-size: .75rem;
  border-radius: .25rem;
  border: 1px solid #EBE7F2;
  box-shadow: 0 0 .313rem #eae7e4;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #ebe7f2;
  position: absolute;
  top: 0;
  right: calc(50% - .313rem);
  transform: rotate(90deg);
  
  div {
    position: relative;
    top: -.625rem;
    left: 2px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #FFF;
  }
`;

const StarsContainer = styled.div`
  top: .625rem;
  transition: .3s;
  right: 0;
  position: absolute;
  padding-top: .938rem;
  background: transparent;
  display: ${props => props.visible ? "block" : "none"};
    
  @media only screen and (min-width: ${breakpoints.md}) {
    right: -6.938rem;
  }
`;

const RoomCopy = styled.p`
  color: var(--sharkGrey);
    
    b {
        font-weight: normal;
    }
`;

const Features = styled.div`
  margin-top: .875rem;

  .change-dates {
    font-weight: bold;
    color: var(--primary);
    font-size: 0.75rem;
    margin-top: 0.5rem;
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    margin-top: 0.5rem;
    padding: .25rem .5rem;  
    border-left: 1px solid var(--smokeGrey);
  }
`;

const FreeCancellation = styled.div`
  display: flex;
  flex-direction: column;
    
  @media only screen and (min-width: ${breakpoints.sm}) {
    flex-direction: ${props => props.isMap ? "column": "row"};
    align-items: ${props => props.isMap ? "left": "center"};
  }
    
  @media only screen and (min-width: 1060px) {
    flex-direction: column;
    align-items: start;
  }

  @media only screen and (min-width: 1200px) {
    flex-direction: column;
    align-items: left;
  }

  @media only screen and (min-width: ${breakpoints.xl}) {
    flex-direction: ${props => props.isMap ? "column" : "row"};
    align-items: ${props => props.isMap ? "left": "center"};
  }
`;

const BComBanner = styled.div`
  position: absolute;
  z-index: 2;
  background: yellow;
  padding: 0.5rem 1rem;
`

const FreeCancellationInfo = styled.div`
  display: none;
    
  @media only screen and (min-width: ${breakpoints.md}) {
    display: block;
    line-height: 1.4;
    font-size: 0.75rem;
    color: var(--green);
  }
`;

const ButtonBlock = styled.div`
  display: none;
  pointer-events: ${props => props.isUsersHotel ? 'none' : 'hover'};

  @media only screen and (min-width: ${breakpoints.md}) {
    display: ${props=> props.isMap ? "none" : "block"};
    text-align: right;  
    margin-top: 0.75rem;
  }
    
  @media only screen and (min-width: ${breakpoints.xl}) {
    display: ${props=> props.isMap ? "none" : "block"};
  }


  button {
    border: none;
    background: var(--primary);
    color: var(--white);
    font-family: Montserrat,sans-serif;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 700;
    border-radius: 4px;
    transition: background .2s ease-in-out;

    &:hover {
      background: var(--lightenPrimary);
    }

    @media only screen and (min-width: ${breakpoints.lg}){
      padding: 1rem 1.5rem;
    }
  }
  
`;

const Top = styled.div`
  display: flex;
  grid-column-gap: 1rem;
  justify-content: space-between;
`;

const Bottom = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
    
  @media only screen and (min-width: 1060px) {
    flex-direction: ${props=> props.isMap || props.isRegionSEO ? "column" : "row"};
    grid-column-gap: 1rem;
  }
`;

const NonRefundable = styled.div`
  font-weight: 700;
  font-size: 0.75rem;
  color: var(--black);
`;

const Image = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 14.75rem;
  object-fit: cover;
  background-color: var(--grey);

  @media only screen and (min-width: ${breakpoints.md}) {
    margin: 0;
    width: 100%;
    height: 20.75rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    height: 21.75rem;
  }

  @media only screen and (min-width: 1180px) {
    height: 15rem;
  }
`;

const Deal = styled.p`
  background: var(--green);
  color: var(--white);
  border-radius: 4px;
  padding: .2rem .65rem;
  box-sizing: border-box;
  font-weight: 700;
  width: fit-content;
  font-size: .75rem;
  margin-top: .35rem;
  position: relative;

  .tooltip {
    display: none;
    position: absolute;
    pointer-events: none;
    bottom: -80px;
    color: white;
    background: #410987;
    padding: .2rem .65rem;
    left: 0;
    border-radius: 4px;
    border: none;
    opacity: 0;
    width: max-content;
    transition: all .5s ease;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-color: transparent transparent #410987;
      border-style: solid;
      border-width: 0 .625rem .625rem;
      position: absolute;
      top: -.625rem;
      left: 2.5rem;
    }
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    .tooltip {
      display: block;
    }
    
    &:hover .tooltip {
      opacity: 1;
      bottom: -30px;
    }
  }
`;

const Was = styled.span`
  color: var(--red);
  font-size: .75rem;
  margin-right: .25rem;
  text-decoration: line-through;
`;

const FakeCheckBox = styled.div`
  gap: 0.25rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-top: 0.75rem;

  input {
    visibility: hidden;
  }

  svg {
    width: .75rem;
    height: .75rem;
    display: block;
  }
  
  &:hover {
    color: var(--primary);
    text-decoration: underline;
  }
`;

const CheckboxLabel = styled.div`
  cursor: pointer;
  font-weight: 100;
  font-size: 0.875rem;
  white-space: nowrap;
  line-height: 1.125rem;
  color: var(--primary);
`;

const BComContainer = styled.div`
  display: block;
  
  &.desktop {
    display: none;
  }
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    display: none;
    
    &.desktop {
      display: block;
    }
  }
`;

const CardDescription = styled.p`
  font-size: .75rem;
  color: black;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  line-height: 1.25rem;
  margin: 0;
  padding: .25rem .5rem;
  border-left: 1px solid #F5F4F3;
  @media only screen and (min-width: 1180px) {
    -webkit-line-clamp: 2;
  }
`

const BCOM = ({handleBcClick}) => {
  return (
    <FakeCheckBox onClick={(e) => handleBcClick(e)}>
      <Icon name="icon-share" />
      <CheckboxLabel>Compare price on Booking.com</CheckboxLabel>
    </FakeCheckBox>
  )
}

const Card = ({property, nights, url, rooms, index, featuresList, isUsersHotel, isMap, cardSelected, handleSetProperties, qsCheckin, qsCheckout, isSanityComponent, isHotel, isRegionSEO,  isPPC, checkin, checkout, probablyTheHotelUserRequested, breakfastIncluded}) => {
  const [visibleStarsInfo, setStarsInfoVisible] = useState(null);
  const [mouseOnStars, setMouseOnStars] = useState(false);
  const [isHotelFirstResult, setIsHotelFirstResult] = useState(false);
  
  let descriptionText = property.property.description ? property.property.description.text ? property.property.description.text : property.property.description.location ? property.property.description.location : null : null;

  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  const toggleModal = (e) => {
    e.preventDefault();
    setModalVisible(true, e, true)
  }

  const toggleStarsInfo = (index, e) => {
      e.preventDefault();
      setStarsInfoVisible(index);
  }

  const handleCardSelected = (index) => {
    if(!isMap) return
    cardSelected(index);
  }

  const foundFeatures = {};

  const keyWords = ['breakfast'];

  property.property.facilities.forEach(element => {
      for (const keyword of keyWords) {
          if (element.toLowerCase().includes(keyword)) {
              foundFeatures[keyword] = element;
          }
      }
  })

  const handlePropClick = function (e, url) {
    if (handleSetProperties) handleSetProperties(property);
    if (typeof window !== "undefined" ) {
      let url = window.location.href;
      window.localStorage.setItem("url", url);

        if (window.td) {
          window.td.trackEvent("uiactions", {
            category: "property_card_click",
            value: {
                time: new Date().getTime(),
                property: property.property.id,
                ga4Cookie: getCookie("_ga_40SG3BGWV2"),
                gaclient: getCookie("_ga"),
                propertySource: property.bookingComDeeplink ? 'Booking.com' : 'Expedia'
            },

            tracking_profile_id: getCookie("tracking_profile_id")
        })
        window.td.set('$global', 'td_global_id', 'td_global_id');
      }
    }
  }

  const handleBcClick = function (e) {
    e.preventDefault();
    let destId = property.property.sources.booking_com;
    let region;

    if (!destId) {
      let hotelName = property.property.name;
      let cityName = property.property.address.city;
      let countryName = property.property.address.state;
      region = encodeURIComponent(hotelName + " " + cityName + " " + countryName).replace(/%20/g, "+");
    }

    let destType = 'hotel';
    let adults = rooms.reduce((sum, item) => sum + item.adults, 0);

    let children = rooms.reduce((sum, item) => {
      if (item.children) {
        return sum + item.children.length;
      } else {
        return sum;
      }
    }, 0);

    let ageParams = "";

    rooms.forEach(item => {
      if (item.kidsAge) {
        item.kidsAge.forEach(age => {
          ageParams += "&age=" + age;
        });
      }
    });

    let gclid = location.href.includes("gclid=") ? location.href.split("gclid=")[1] : "";
    gclid = gclid.includes("&") ? gclid.split('&')[0] : gclid;

    let bcUrl = "https://www.booking.com/searchresults.en.html?" + (destId ? ('dest_id=' + destId) : ('ss=' + region)) + "&dest_type=" + destType + "&checkin=" + checkin + "&checkout=" + checkout + "&do_availability_check=1&order=popularity&group_adults=" + adults + "&no_rooms=" + rooms.length + "&group_children=" + children + "&aid=1937284" + (ageParams ? ageParams : '') + "&label=LR-comparison" + (getCookie("tracking_profile_id") ? "-" + getCookie("tracking_profile_id") : "") + (gclid ? "-" + gclid : "");
    window.open(bcUrl, "_blank");
  }

  const renderStars = (stars) => {
      const totalAmount = 5;
      return [...Array(totalAmount)].map((el, i) =>
          i < stars && i + 1 > stars ? (
                  <img key={i} src={HalfStar} alt={"Half Star"}/>
              ) :
              i < stars ? (
                  <img key={i} src={Star} alt={"Full Star"}/>
              ) : (
                  <div key={i}/>
              ),
      );
  };

  const stars = property.property.rating.stars;

  const toggleMouseStarsInfo = (boolean) => {
      setMouseOnStars(boolean);

      if (visibleStarsInfo !== null && boolean === false) {
          setTimeout(() => {
              setStarsInfoVisible(null);
          }, 500);
      }
  }

  const handleOpenCal = (e) => {
    e.preventDefault();
    document.querySelector("#checkInDate")?.click();
    document.querySelector("#addDates")?.click();
    setTimeout(() => {
      document.querySelector(".date")?.click();
    }, 500);
  }

  function isDateFormatYYYYMMDD(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
}

  const getDateAndMonth = (dateString) => {
    
    // when we get YYYY-MM-DD format
    let formattedDate
    if(isDateFormatYYYYMMDD(dateString)) {
      formattedDate = new Date(dateString).toDateString();
    } else {
      formattedDate = dateString;
    }

    const parts = formattedDate.split(" ");
    const result = `${parts[1]} ${parts[2]}`;

    return result
  }

  let distance;

  if (property.distance) {
      distance = parseFloat(property.distance).toFixed(2);
  }

  let notProduction; 
  if (typeof window !== `undefined`) {
    notProduction = window.location.origin.includes("localhost") || window.location.origin.includes("staging") || window.location.origin.includes("development") ? true : false;
  }

  useEffect(()=> {
      if ( index === 0 && isHotel && !probablyTheHotelUserRequested) {
          setIsHotelFirstResult(true);
      }
  }, [])

  let cardCrumbs = property.property.location.breadcrumbs;
  let lastCrumb = cardCrumbs && cardCrumbs.length > 0 ? cardCrumbs[cardCrumbs.length -1].name : null;
  let sourceType = property.property.type.sourceType
  if (sourceType.toLowerCase().includes("hostel")) sourceType = "Hostel"
  if (property.property.seoAttributes && property.property.seoAttributes.hotelName) property.property.name = property.property.seoAttributes.hotelName;
  if (property.property.seoAttributes && property.property.seoAttributes.marketingDescription) descriptionText = property.property.seoAttributes.marketingDescription;
  
  let dealName = property.dealTagging ? property.dealTagging.dealName : false;
  if(dealName && /save[0-9]/gi.test(dealName)) dealName = dealName.replace(/save/gi, "Save ");


  let readableCheckin = new Date(checkin).toString().substring(0, 10);
  let readableCheckout =new Date(checkout).toString().substring(0, 10);

return (
  <CardContainer key={index} href={url} data-propid={property.property.id} target={!isRegionSEO && '_blank'} onClick={(e) => handlePropClick(e, url)} onMouseEnter={() => handleCardSelected(index)} onMouseLeave={() => handleCardSelected(null)} isMap={isMap} isUsersHotel={isUsersHotel}>

      {property.bookingComDeeplink && notProduction && <BComBanner>B.com prop</BComBanner>}
      <CardBlock isUsersHotel={isUsersHotel} isHotelFirstResult={isHotelFirstResult}>
          <Left isMap={isMap}>
              {property.property.images.length > 0 &&
                  <Image key={index} loading="lazy" src={property.property.images[0].small} alt={property.property.images[0].caption} />
              }
          </Left>
          <Right isMap={isMap}>
              <Top>
                  <div>
                      <Name isMap={isMap}>{property.property.name}</Name>
                      <Stars title={stars + " stars"}
                             onClick={(e) => toggleStarsInfo(index, e)}
                             onMouseEnter={() => toggleMouseStarsInfo(true, index)}
                             onMouseLeave={() => toggleMouseStarsInfo(false, index)}>
                          {renderStars(stars)}
                          <StarsContainer visible={visibleStarsInfo === index}
                                          onMouseEnter={() => toggleMouseStarsInfo(true, index)}
                                          onMouseLeave={() => toggleMouseStarsInfo(false, index)}>
                              <StarsInfo>
                                  <Triangle>
                                      <div/>
                                  </Triangle>
                                  This star rating is provided to LateRooms by the property. It is usually determined
                                  by an official hotel rating organisation or another third party.
                              </StarsInfo>
                          </StarsContainer>
                      </Stars>
                  </div>
              </Top>
              <Bottom isMap={isMap} isRegionSEO={isRegionSEO}>
                  <div>
                      <Copy>
                        {property.property.address &&
                          <Address>
                              {lastCrumb && <span>{sourceType ? sourceType : "Hotel"} in {lastCrumb}{(property.property.address.city && property.property.address.city !== lastCrumb) ? `, ${property.property.address.city}` : ""}</span>}
                              {!lastCrumb && property.property.address.city &&<span>{sourceType ? sourceType : "Hotel"} in {property.property.address.city}</span>}
                              {property.distance && distance !== "0.00" &&
                                <React.Fragment>
                                    &nbsp;&#183;&nbsp;
                                    {distance} mi from centre
                                </React.Fragment>
                              }
                          </Address>
                        }
                      </Copy>
                      {property.property.rating.guestRating > 0 &&
                        <Rating rating={property.property.rating.guestRating}
                                reviews={property.property.rating.guestCount}
                        />
                      }
                      {property.dealTagging && property.dealTagging.dealName && dealName &&
                      <Deal>
                        <span class="tooltip">This property is offering a temporary discount on stays</span>
                        {property.dealTagging.dealName === "Mobile" ? "Mobile only deal" : dealName}
                      </Deal>
                      }
                      {isUsersHotel && !propertyTotal ?
                      <Features noPrice={true}>
                        {qsCheckin  ?
                         <NoAvailCopy>This property has no availability for {getDateAndMonth(qsCheckin)} - {getDateAndMonth(qsCheckout)}</NoAvailCopy>
                         :
                         <NoAvailCopy>This property has no availability between {readableCheckin} - {readableCheckout}</NoAvailCopy>
                        }
                        <p className="change-dates" onClick={(e)=>handleOpenCal(e)}>Try changing your dates to see alternate availability</p>
                      </Features>
                        :
                      <Features isMap={isMap}>
                        {breakfastIncluded &&
                          <GreenCopy key={index}>
                            Breakfast included
                          </GreenCopy>
                        }
                          {Object.entries(foundFeatures).map(([feature, name], index) => { return (
                                <GreenCopy key={index}>
                                    {featuresList[feature] && featuresList[feature]}
                                </GreenCopy>
                              )
                          })}
                          <FreeCancellation isMap={isMap}>
                              {property.refundable &&
                                <GreenCopy>Fully refundable</GreenCopy>
                              }
                              {property.refundable && property.propertyPayAvailable && !isMap &&
                                <GreenCopy className="dot">&nbsp;&#183;&nbsp;</GreenCopy>
                              }
                              {property.propertyPayAvailable &&
                                <GreenCopy>Reserve now, pay later</GreenCopy>
                              }
                          </FreeCancellation>
                          {property.refundable &&
                            <FreeCancellationInfo>You can cancel later, so lock in this great price today</FreeCancellationInfo>
                          }
                          { property.availableRooms !== null && property.availableRooms <= 5 &&  property.availableRooms > 1 &&  
                            <RedCopy>Only {property.availableRooms} rooms left</RedCopy>
                          }
                      </Features>
                      }
                      {descriptionText && <CardDescription>{descriptionText}</CardDescription>}
                  </div>
                  <PriceCopy>
                      <ButtonBlock isMap={isMap} isUsersHotel={isUsersHotel}>
                          <button onClick={(e) => handlePropClick(e)}>View details</button>
                        </ButtonBlock>
                  </PriceCopy>
              </Bottom>
          </Right>
      </CardBlock>
  </CardContainer>
)
}

export default Card;