const breakpoints = {
    xxl: "1440px",
    xl: "1280px",
    lg: "992px",
    md: "768px", // breakpoint for mobile
    sm: "576px",
    xs: "375px",
    xxs: "320px",
    desktop: "1024px"
};

export default breakpoints;