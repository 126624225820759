import React, {useEffect, useState} from "react";
import styled from "styled-components";
import breakpoints from "../assets/styles/breakpoints";

const BreadcrumbContainer = styled.div`
  margin-bottom: 1.25rem;
`

const Links = styled.ul`
  margin: 0;
  display: ${props => props.showAtBottom ? "block" : "none"};
  flex-direction: row;
  border-bottom: 1px solid ${props => props.propPageSeo ? 'transparent' : 'var(--borderGrey)'};
  padding: ${props => props.propPageSeo ? '0' : props.searchFormShow ? "0" : props.showAtBottom ? "0" : ".5rem 0 0 0"};
  border: ${props => props.showAtBottom ? '1px solid #D9D9D9' : 'none'};

  li {
    display: flex;
    font-size: 14px;
    list-style: none;
    line-height: 16px;
    flex-direction: row;
    margin: ${props => props.showAtBottom ? "0" : 'initial'};
    border-bottom: ${props => props.showAtBottom ? '1px solid #D9D9D9' : 'none'};

    &:after {
      content: ">";
      width: 16px;
      height: 14px;
      display: ${props => props.showAtBottom ? "none" : 'block'};
      text-align: center;
    }
    
    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }

  a {
    max-width: ${props => props.showAtBottom ? "unset" : "110px"};
    overflow: hidden;
    width: max-content;
    white-space: nowrap;
    text-decoration: none;
    display: inline-block;
    text-overflow: ellipsis;
    position: relative;
    color: var(--primary);
    display: ${props => props.showAtBottom ? "block": "initial"};
    width: ${props => props.showAtBottom ?  "100%": "initial"};
    height: ${props => props.showAtBottom ?  "100%": "initial"};
    padding: ${props => props.showAtBottom ? "1.125rem 1.25rem 1.125rem 2rem" : 'initial'};

    &:before {
      content: "";
      background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxzdmc+CiAgICAgICAgPHBhdGggZD0iTTE2LjU5IDguNTlMMTIgMTMuMTcgNy40MSA4LjU5IDYgMTBsNiA2IDYtNi0xLjQxLTEuNDF6Ij48L3BhdGg+CiAgICA8L3N2Zz4KPC9zdmc+);
      display: block;
      height: 1.75rem;
      width: 1.75rem;
      position: absolute;
      transform: rotate(90deg) translate(-50%);
      left: 5px;
      top: 50%;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  
  @media(min-width: ${breakpoints.md}){
    display: flex;
    border: none;

    li {
      border: none;
      &:after {
        display: block;
      }
    }

    a {
      max-width: unset;
      text-overflow: ellipsis;
      display: inline;
      width: inherit;
      padding: inherit;

      &:before {
        display: none;
    }
  }
}
`;

const Breadcrumbs = ({region, breadcrumbs}) => {

  return (
    <BreadcrumbContainer>
            <Links 
            >
              <li>
                  <a href="/">
                  <span>Home</span>
                  </a>
              </li>
              {(breadcrumbs && breadcrumbs.length > 0) ? breadcrumbs.map((crumb, index) => {
                return (
                  <li key={index}>
                    <a href={"https://www.laterooms.com/hotels/" + crumb.id}>
                    <span>{crumb.name}</span>
                    </a>
                  </li>
                )
                })
                : <li>
                  <span>
                      {region}
                  </span>
                </li>
              }

            </Links>
         </BreadcrumbContainer>
  )
}

export default Breadcrumbs;