import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import Breadcrumbs from './Breadcrumbs';
import SideFilters from './SideFilters';
import CardList from './CardList';
import Section from './Section';
import Sort from "./Sort"


const Container = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;
const SideFiltersContainer = styled.div`
    position: sticky;
    top: 0;
    z-index: ${props => props.showMobileFilters ? "100" : "11"};
    top: 7.375rem;
    background-color: var(--bgWhite);
    &.map {
        overflow: scroll;
    }
    
    @media only screen and (min-width: 1024px) {
      width: 28rem;
      opacity: 1;
      visibility: visible;
    }
`;

const CardListContainer = styled.div`
  padding: 0 1.25rem;
  @media only screen and (min-width: 768px) {
    padding: 0  ;
  }
`;

const Styles = styled.div`

    .property-list {
        margin-top: 3.125rem;
        padding: 0 1.25rem;
    }

    .title, .subtitle {
        margin: 0 0 1.25rem;
    }

    .container {
        display: inline-block;
        @media only screen and (min-width: 768px) {
        padding: 0 1.25rem;
        }
    }

    .grid-container {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 1.25rem;
        margin: 0 0 1.25rem;
    }

    .grid-item {
        background-size: cover;
        background-repeat: no-repeat;
        height: 28.125rem;
        width: 100%;
        box-shadow: 0 0 1.25rem rgb(0 0 0 / 30%);
        border-radius: .25rem;
        background-position: center;
        transition: .2s;

      &:hover {
        box-shadow: 0 0.125rem 1.25rem 0 #a3e0d8;
        transition: .2s;
      }

        .prop {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            text-decoration: none;
            box-sizing: border-box;
            position: relative;
            color: #6E6E6E;
            cursor: pointer;

        }

        .prop-image {
            height: 19.375rem;
            width: 100%;
            background: #6E6E6E;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            .pets {

                height: 2.5rem;
                width: 2.5rem;
                position: absolute;
                top: 1.25rem;
                right: 1.25rem;
                background: var(--primary);
                border-radius: 100%;
                
                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    fill: var(--white);
                    margin: -.625rem 0 0 -.625rem;
                }
            }
        }

        .prop-info {
            padding: 1.25rem;
            height: 8.75rem;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: var(--black);

            .prop-town {
                font-size: .75rem;
            }

            .prop-link {
                float: right;
                font-weight: 700;
                color: var(--primary);
            }

            .prop-foot {
                padding-top: .85rem;
                border-top: 1px solid #D8D8D8;
            }
        }
    }

    .search-button {
        border: none;
        background-color: var(--secondary);
        position: relative;
        height: 3.125rem;
        z-index: 1;
        border-radius: .313em;
        line-height: 3.125rem;
        font-size: 0.875rem;
        font-weight: 700;
        color: var(--white);
        text-align: center;
        outline: 0;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
        display: inline;
        width: calc(50% - 0.3125rem);
        margin: 0;
        cursor: pointer;
        width: 100%;

        &:hover,
        &:focus {
            background: var(--lightenButton)
        }
    }


    @media only screen and (min-width: 1024px) {

        .property-list {
            padding: 0;
        }

        .container {
            display: inline-block;
            margin-left: 50%;
            transform: translate(-50%);
            padding: 0;
        }

        .grid-container {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .grid-item {
            height: 28.125rem;

        }

        .search-button {
            width: 30%;
            float: right;
        }
    }
`;


const LrSearch = (props) => {
    const [isDesktop, setIsDesktop] = useState(true);
    const [allowRedirect, setAllowRedirect] = useState(false)
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const [isShowSortModal, setIsShowSortModal] = useState(null);
    const [initRender, setInitRender] = useState(false);

    //manage default dates
    let thisFriday = new Date();
    const day = thisFriday.getDay();
    const targetDay = 5;  // Friday
    let dayOffset = targetDay - day;
    if (dayOffset < 0) dayOffset += 7;

    thisFriday = new Date(thisFriday.getTime() + (dayOffset * 24 * 3600 * 1000));

    let thisMonday = new Date(thisFriday);
    let numberOfDayToAdd = 3;
    thisMonday.setDate(thisMonday.getDate() + numberOfDayToAdd );

    thisFriday = thisFriday.toISOString().split('T')[0];
    thisMonday = thisMonday.toISOString().split('T')[0];

// This component is similar to the searchSSR/search component in lr-gatsby.
// However, it handles state management differently and should be a bit simpler.

// 0. The filter state in this component is managed only by `allFilters`.
// 1. We set the `allFilters` state from `props.params`, which comes from the LR API,
//    and show the filters on the UI based on this state.
// 2. When the user clicks or toggles a filter or sort option, we update the `allFilters` state
//    using functions like `toggleFilter` and `setGuestRating`.
// 3. After updating `allFilters`, `useEffect` detects the change and runs the `redirectToSearch`
//    function on desktop. On mobile, we skip the `useEffect` route and run `redirectToSearch`
//    when `applyMobileFilters` is triggered by clicking the Done button.

    const [allFilters, setAllFilters] = useState({
        'popular': [],
        'facility': [],
        'property': [],
        'stars': [],
        'price':  props.params ? props.params.priceFrom ? [props.params.priceFrom, props.params.priceTo] : [0, 1000]: [0, 1000],
        'guestRating':  0,
        'free-cancellation':  false,
        // applied filters used to update the graphql query
        appliedFilters: {
          refundable: props.params ? props.params.refundable : null, 
          gym: props.params ? props.params.gym: null,
          wifi: props.params ? props.params.wifi: null,
          pool: props.params ? props.params.pool: null,
          restaurant: props.params ? props.params.restaurant: null,
          parking: props.params ? props.params.parking: null,
          hotTub: props.params ? props.params.hotTub: null,
          spa: props.params ? props.params.spa: null,
          airCon: props.params ? props.params.airCon: null,
          wheelchair: props.params ? props.params.wheelchair: null,
          familyFriendly: props.params ? props.params.familyFriendly: null,
          petFriendly: props.params ? props.params.petFriendly: null,
          payAtProperty: props.params ? props.params.payAtProperty: null,
          propertyType: props.params ?  props.params.propertyType ? props.params.propertyType : null: null,
          breakfastIncluded: props.params ? props.params.breakfastIncluded : null,
          stars: props.params ? props.params.stars: null,
          price: props.params ? props.params.priceFrom ? [props.params.priceFrom, props.params.priceTo] : [0, 1000]: [0, 1000],
          guestRating:  props.params ? props.params.guestRating ? props.params.guestRating : null: null,
          sort: props.params ? props.params.sortOrder ? props.params.sortOrder : "default": "default",
          membersDiscount: props.params ? props.params.memberRate: null,
          breakfastIncluded: props.params ? props.params.breakfastIncluded : null,
        }
      });


    let rooms = null;
    let roomConfig = rooms ? JSON.parse(rooms) : [{adults: 2, children: []}];



    const popularFilters = [
        {
            label: "Wifi",
            value: "wifi"
        },
        {
            label: "Pool",
            value: "pool"
        },
        {
            label: "Parking",
            value: "parking"
        },
        {
            label: "Gym",
            value: "gym"
        },
        {
            label: "Wheelchair",
            value: "wheelchair"
        },
        {
            label: "Breakfast included",
            value: "breakfastIncluded"
        }
    ]

    
    const applyMobileFilters = () => {
        setTimeout(() => {
        redirectToSearch()
        }, 500);
    }
    
    const handleResize = () => {
        if (typeof window !== `undefined`) {
            if (window.innerWidth >= 768 && isDesktop !== true) {
                setIsDesktop(true)
            } else if (window.innerWidth < 768 && isDesktop !== false) {
                setIsDesktop(false);
            }
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            handleResize();
            window.addEventListener("resize", handleResize);
        }
    }, []);

    const toggleFilter = (name, element, star) => {
        switch(name) {
            case 'free-cancellation':
                    setAllFilters(prevFilters => ({
                        ...prevFilters,
                        appliedFilters: {
                            ...prevFilters.appliedFilters,
                            refundable : allFilters.appliedFilters.refundable ? null : true
                        }
                    }));   
            break;

            case 'memberRate':
                setAllFilters(prevFilters => ({
                    ...prevFilters,
                    appliedFilters: {
                        ...prevFilters.appliedFilters,
                        membersDiscount: allFilters.appliedFilters.membersDiscount ? null : true 
                    }
                }));
            break;


            case "stars":
                if (allFilters.appliedFilters.stars && allFilters.appliedFilters.stars.includes(element.value)) {
                    // Turn off
                    const newStars = allFilters.appliedFilters.stars.filter(value => value !== element.value);
            
                    setAllFilters(prevFilters => ({
                        ...prevFilters,
                        appliedFilters: {
                            ...prevFilters.appliedFilters,
                            stars: newStars.length > 0 ? newStars : null
                        }
                    }));
                } else {
                    // Turn on
                    const newStars = allFilters.appliedFilters.stars ? [...allFilters.appliedFilters.stars, element.value] : [element.value];
            
                    setAllFilters(prevFilters => ({
                        ...prevFilters,
                        appliedFilters: {
                            ...prevFilters.appliedFilters,
                            stars: newStars
                        }
                    }));
                }
                break;
            
                case "propertyType":

                    setAllFilters(prevFilters => ({
                        ...prevFilters,
                        appliedFilters: {
                            ...prevFilters.appliedFilters,
                            propertyType: allFilters.appliedFilters.propertyType ? allFilters.appliedFilters.propertyType === element.value ? null : element.value : element.value
                        }
                    }));
                break;

                // this is only when removing filters using the applied filter buttons
                case "appliedFilters":
                    // for stars 
                    if (star && allFilters.appliedFilters.stars && allFilters.appliedFilters.stars.includes(star)) {
                        // Turn off
                        const newStars = allFilters.appliedFilters.stars.filter(value => value !== star);

                        setAllFilters(prevFilters => ({
                            ...prevFilters,
                            appliedFilters: {
                                ...prevFilters.appliedFilters,
                                stars: newStars.length > 0 ? newStars : null
                            }
                        }));
        
                    } else {
                        if (element === 'price') {
                            setAllFilters(prevFilters => ({
                                ...prevFilters,
                                appliedFilters: {
                                    ...prevFilters.appliedFilters,
                                    price: [0,1000]
                                }
                            }));
                        }

                        setAllFilters(prevFilters => ({
                            ...prevFilters,
                            appliedFilters: {
                                ...prevFilters.appliedFilters,
                                [element]: null 
                            }
                        }));
                    }
                break;
 

            default:
                setAllFilters(prevFilters => ({
                    ...prevFilters,
                    appliedFilters: {
                        ...prevFilters.appliedFilters,
                        [element.value]: !prevFilters.appliedFilters[element.value] 
                    }
                    }));
            break;
        }
    }

    const setSliderValue = (e, element) => {

          setAllFilters(prevFilters => ({
            ...prevFilters,
                price: [e[0],e[1] ],
            }));
    }

    const setGuestRating = (e, element) => {
        if (element !== "guestRating") {
            return
        }

        const rating = Number(e)

         setAllFilters(prevFilters => ({
            ...prevFilters,
            appliedFilters: {
                ...prevFilters.appliedFilters,
                [element]: rating 
            }
        }));
            
    }

    const setSliderValueAfter = (e, element) => {
       

        setAllFilters(prevFilters => ({
            ...prevFilters,
            appliedFilters: {
                ...prevFilters.appliedFilters,
                price: [e[0],e[1] ]
            }
            }));
    }

    const toggleSortMobile = (sort) => {

        setAllowRedirect(true);

            setAllFilters(prevFilters => ({
                    ...prevFilters,
                    appliedFilters: {
                        ...prevFilters.appliedFilters,
                        sort: sort.value
                    }
            }));
    }

    const toggleMemberDiscount = () => {

        if (allFilters.appliedFilters.memberRate) {
            setAllFilters(prevFilters => ({
                ...prevFilters,
                appliedFilters: {
                    ...prevFilters.appliedFilters,
                    membersDiscount: null 
                }
            }));
        } else {
            setAllFilters(prevFilters => ({
                ...prevFilters,
                appliedFilters: {
                    ...prevFilters.appliedFilters,
                    membersDiscount: true 
                }
            }));
        }

    }


    const searchInNewTab = () => {
        const reversedBreadcrumbs = props.breadcrumbs ?  Array.from(props.breadcrumbs).reverse() : null;
        let mappedReversedBreadcrumbs = reversedBreadcrumbs ? reversedBreadcrumbs.map((elem) => elem.name).join(", ") : null;
        let baseUrl =`https://www.laterooms.com/searches/?region=${props.params.query ? encodeURIComponent(props.params.query) : mappedReversedBreadcrumbs ? encodeURIComponent(mappedReversedBreadcrumbs) : "United%20kingdom"}&lat=${props.params.centre ? props.params.centre.lat : ""}&lng=${props.params.centre ? props.params.centre.lon : ""}&checkin=${thisFriday}&checkout=${thisMonday}&rooms=`

        var win = window.open(baseUrl, '_blank');
        win.focus();
    }

    const clearFiltersMobile = () => {
  
        if (typeof window !== `undefined`) {

            setAllFilters({ 
                popular: [],
                facility: [],
                property: [],
                stars: [],
                price: [0, 1000],
                'guestRating': 0,
                "free-cancellation": false,
                appliedFilters: {
                    refundable: null, 
                    gym: null,
                    wifi: null,
                    pool: null,
                    restaurant: null,
                    parking: null,
                    hotTub: null,
                    spa: null,
                    airCon: null,
                    wheelchair: null,
                    familyFriendly: null,
                    petFriendly: null,
                    payAtProperty: null,
                    propertyType: null,
                    breakfastIncluded: null,
                    stars: null,
                    price: [0, 1000],
                    guestRating: null,
                    sort: "default",
                    membersDiscount: null,
                    breakfastIncluded: null,
                }
            })
        }
    }


    const handleClearFilters = () => {
        redirectToSearch("clear")
    }

    const getUrlParamsFromAppliedFilters = () => {
        const queryParams = Object.entries(allFilters.appliedFilters)
        .filter(([key, value]) => value !== null) 
        .flatMap(([key, value]) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        })
        .join('&'); 

        return queryParams

    }
    
    const redirectToSearch = (params, isMobile, isMap) => {
        let url;
        let newParams;
        const reversedBreadcrumbs = props.breadcrumbs ?  Array.from(props.breadcrumbs).reverse() : null;
        let mappedReversedBreadcrumbs = reversedBreadcrumbs ? reversedBreadcrumbs.map((elem) => elem.name).join(", ") : null;
        let baseUrl =`https://www.laterooms.com/searches/?region=${props.params.query ? encodeURIComponent(props.params.query) : mappedReversedBreadcrumbs ? encodeURIComponent(mappedReversedBreadcrumbs) : "United%20kingdom"}&lat=${props.params.centre ? props.params.centre.lat : ""}&lng=${props.params.centre ? props.params.centre.lon : ""}&checkin=${thisFriday}&checkout=${thisMonday}&rooms=`


        // return
        if (isMobile) {
    
          if (params.startsWith('?')) {
            newParams = '&' + params.slice(1);
          }
          url = baseUrl + `&${newParams}`
    
        } else {
    
          if (params === 'clear') {
            //update filter route
            url = baseUrl
          } else {
            // return
            const currentFilterParams = getUrlParamsFromAppliedFilters()
            url = baseUrl + "&" + currentFilterParams

            if (isMap) {
                url = url +  "&isMap=true"
            }

          }
        }
    
        if (typeof window !== `undefined`) {
          window.location.href = url;
        }
        
    };

    const handleSortModal = () => {
        if (isShowSortModal) {
            document.body.style.overflow = 'visible'
        } else {
            document.body.style.overflow = 'hidden'
        }

        setIsShowSortModal(!isShowSortModal)
    }



    useEffect(() => {

        if (allowRedirect) {
            redirectToSearch()
            return
        }
        //Prevent auto-redirect without clicking the Done button on mob
        if (!isDesktop) return
        
        if (!initRender) {
            setInitRender(true)
            return
        }
        
        redirectToSearch();

    }, [allFilters.appliedFilters]);


    return (
        <Section>
            <Styles>
                <div className="container">
                <div className="col-1 hidden-m"/>
                    <div className="col-10">
                        <Breadcrumbs 
                          region={props.region}
                          breadcrumbs={props.breadcrumbs}
                        />
                    <Container>
                        <SideFiltersContainer 
                        showMobileFilters={showMobileFilters}
                        >
                            <SideFilters 
                            allFilters={allFilters}
                            popularFilters={popularFilters}
                            region={props.region}
                            toggleFilter={toggleFilter}
                            setGuestRating={setGuestRating}
                            setSliderValue={setSliderValue}
                            setSliderValueAfter={setSliderValueAfter}
                            toggleSortMobile={toggleSortMobile}
                            handleClearFilters={handleClearFilters}
                            clearFiltersMobile={clearFiltersMobile}
                            toggleMemberDiscount={toggleMemberDiscount}
                            redirectToSearch={redirectToSearch}
                            showMobileFilters={showMobileFilters}
                            setShowMobileFilters={setShowMobileFilters}
                            handleSortModal={handleSortModal}
                            isShowSortModal={isShowSortModal}
                            applyMobileFilters={applyMobileFilters}
                            />
                        </SideFiltersContainer>
                        <CardListContainer>
                            <Sort 
                              totalCount={props.totalCount}
                              allFilters={allFilters}
                              toggleSortMobile={toggleSortMobile}
                              region={props.params.query}
                              isDesktop={isDesktop}
                              showMob={true}
                              />
                            <CardList 
                                properties={props.properties}
                                allFilters={allFilters}
                                rooms={rooms}
                                roomConfig={roomConfig}
                                isPayAtProperty={false}
                                isMap={false}
                                region={props.region}
                                searchInNewTab={searchInNewTab}
                                />
                        </CardListContainer>
                    </Container>
                </div>
                </div>
            </Styles>
        </Section>
    )
}

export default LrSearch;